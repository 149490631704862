import { Form, Select, Input, DatePicker, Button, InputNumber  } from "antd";
import * as dayjs from 'dayjs'
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useOutletContext  } from "react-router-dom";
import { Products } from "./products";

const { TextArea } = Input;

export const Order = () => {
    const navigate  = useNavigate();
    const [product, setProduct] = useState();
    const [form] = Form.useForm();
    const [formData, setFormData] = useState({
        height: 0,
        width: 0,
        remark: ''
    });
    const [, setSelectData] = useOutletContext();

    const selectHandle = useCallback((val) => {
        const p = Products.find(p => p.value === val)
        setProduct(p)
    }, []);

    const inputHanel = (key) => (val) => {
        setFormData(pre => ({...pre, [key]: val}))
    }
    const onFinish = useCallback((values) => {
        const data = {...values, currentPro: product};
        localStorage.setItem('printData', JSON.stringify(data));
        setSelectData(data);
        Promise.resolve().then(() => {
            navigate('/print');
        })
    }, [product, setSelectData, navigate])

    useEffect(() => {
      const data = localStorage.getItem('printData');
      if (data) {
        const d = JSON.parse(data);
        if (d.time) {
          d.time  = dayjs(d.time);
        }
        form.setFieldsValue(d);
        setFormData(d);
        const p = Products.find(p => p.value === d.currentPro.value);
        if (p) {
          setProduct(p)
        }
        setSelectData(data);
      }
    }, [form, setSelectData])

  return (
    <div className="order-page">
      <Form form={form} onFinish={onFinish}>
        <Form.Item rules={[{ required: true, message: '请输入客户名称' }]} label="客户名称" name="useName">
          <Input />
        </Form.Item>
        <Form.Item label="交货日期"  rules={[{ required: true, message: '请输入交货日期' }]}   name="time">
          <DatePicker showTime  />
        </Form.Item>
        <Form.Item label="单号"  rules={[{ required: true, message: '请输入单号' }]}  name="prodNum">
          <Input />
        </Form.Item>
        <Form.Item label="选择产品"  rules={[{ required: true, message: '请选择产品' }]}  name="currentPro">
          <Select onSelect={selectHandle}>
            {Products.map((pro) => (
              <Select.Option key={pro.name} value={pro.value}>{pro.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="高（h）"  rules={[{ required: true, message: '请输入高' }]}  name="height">
          <InputNumber  onChange={inputHanel('height')} addonAfter="mm" />
        </Form.Item>
        <Form.Item label="宽（w）" name="width"  rules={[{ required: true, message: '请输入宽' }]} >
          <InputNumber   onChange={inputHanel('width')}  addonAfter="mm"/>
        </Form.Item>
        <Form.Item label="备注" name="remark">
          <TextArea rows={4}  />
        </Form.Item>
        <Form.Item>
          <Button className="sub-btn" type="primary" htmlType="submit">
            打印
          </Button>
        </Form.Item>
      </Form>

      <div className="select_result">
        {product && <>
            {product.params.map(param => <div key={param.desc} className="param-item">
            <span className="sp1">{param.desc}：</span> 
            {param.subParams.map(subP => <span key={subP.desc} className="p2">
            <i>{subP.desc}（{subP.alias.h}：{(subP.h(formData.height) || 0).toFixed(2)}，{subP.alias.w}：{(subP.w(formData.width) || 0).toFixed(2)}）</i>
            </span>)}
            </div>)}
        </>} 
      </div>
    </div>
  );
};
