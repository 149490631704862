import { useState } from "react";
import { Link, Outlet } from "react-router-dom";

export const Home = () => {
    const [selectData, setSelectData] = useState();
  return (
    <div className="app">
      <div className="app-header">
        <div className="logo">欧尚家居</div>
      </div>
      <div className="app-body">
        <div className="app-side">
          <div className="app-menu">
            <Link className="menu-item" to="/order">
              <span>下单</span>
            </Link>
          </div>
        </div>
        <div className="app-content">
          <div className="app-content-inner">
            <Outlet context={[selectData, setSelectData]} />
          </div>
        </div>
      </div>
    </div>
  );
};
