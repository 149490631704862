import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "./pages";
import { Order } from "./pages/Order";
import { Result } from "./pages/Result";

import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}>
          <Route path="order" element={<Order />} />
          <Route path="print" element={<Result />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
