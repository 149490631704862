export const Products = [
  {
    value: "product1",
    name: "平开门（不钢化）",
    params: [
      {
        desc: "18 * 35 金佳福",
        subParams: [
          {
            desc: "外框",
            h: (h) => {
              return h + 21;
            },
            alias: {
              h: "立柱",
              w: "横头",
            },
            w: (w) => {
              return w + 42;
            },
          },
          {
            desc: "内框",
            h: (h) => {
              return h - 30;
            },
            alias: {
              h: "光企",
              w: "上下方",
            },
            w: (w) => {
              return w - 42;
            },
          },
          {
            desc: "玻璃",
            h: (h) => {
              return h - 30 - 22;
            },
            alias: {
              h: "高",
              w: "宽",
            },
            w: (w) => {
              return w - 42 - 22;
            },
          },
        ],
      },
      {
        desc: "16 * 45 鹏飞",
        subParams: [
          {
            desc: "外框",
            h: (h) => {
              return h + 20;
            },
            alias: {
              h: "立柱",
              w: "横头",
            },
            w: (w) => {
              return w + 40;
            },
          },
          {
            desc: "内框",
            h: (h) => {
              return h - 31;
            },
            alias: {
              h: "光企",
              w: "上下方",
            },
            w: (w) => {
              return w - 42;
            },
          },
          {
            desc: "玻璃",
            h: (h) => {
              return h - 31 - 21;
            },
            alias: {
              h: "高",
              w: "宽",
            },
            w: (w) => {
              return w - 42 - 21;
            },
          },
        ],
      },
      {
        desc: "45 * 35 中窄",
        subParams: [
          {
            desc: "外框",
            h: (h) => {
              return h + 27;
            },
            alias: {
              h: "立柱",
              w: "横头",
            },
            w: (w) => {
              return w + 54;
            },
          },
          {
            desc: "内框",
            h: (h) => {
              return h - 30;
            },
            alias: {
              h: "光企",
              w: "上下方",
            },
            w: (w) => {
              return w - 43;
            },
          },
          {
            desc: "玻璃",
            h: (h) => {
              return h - 30 - 79;
            },
            alias: {
              h: "高",
              w: "宽",
            },
            w: (w) => {
              return w - 43 - 79;
            },
          },
        ],
      },
      {
        desc: "75x35",
        subParams: [
          {
            desc: "外框",
            h: (h) => {
              return h +30 ;
            },
            alias: {
              h: "立柱",
              w: "横头",
            },
            w: (w) => {
              return w + 60;
            },
          },
          {
            desc: "内框",
            h: (h) => {
              return h - 41;
            },
            alias: {
              h: "光企",
              w: "上下方",
            },
            w: (w) => {
              return w - 65;
            },
          },
          {
            desc: "玻璃",
            h: (h) => {
              return h - 41 - 128;
            },
            alias: {
              h: "高",
              w: "宽",
            },
            w: (w) => {
              return w - 65 - 128;
            },
          },
        ],
      },
      {
        desc: "85x35凌锋",
        subParams: [
          {
            desc: "外框",
            h: (h) => {
              return h + 43;
            },
            alias: {
              h: "立柱",
              w: "横头",
            },
            w: (w) => {
              return w + 85;
            },
          },
          {
            desc: "内框",
            h: (h) => {
              return h - 45;
            },
            alias: {
              h: "光企",
              w: "上下方",
            },
            w: (w) => {
              return w - 68;
            },
          },
          {
            desc: "玻璃",
            h: (h) => {
              return h - 45 - 148;
            },
            alias: {
              h: "高",
              w: "宽",
            },
            w: (w) => {
              return w - 68 - 148;
            },
          },
        ],
      },
    ],
  },
  {
    value: 'product2',
    name: '隔断门',
    params: [
      {
        desc: "75型材",
        subParams: [
          {
            desc: "立柱",
            h: (h) => {
              return h ;
            },
            alias: {
              h: "立柱",
              w: "横头",
            },
            w: (w) => {
              return w;
            },
          },
          {
            desc: "内框",
            h: (h) => {
              return h-50;
            },
            alias: {
              h: "光企",
              w: "上下方",
            },
            w: (w) => {
              return (w - 50 + 75) / 2 + 6;
            },
          },
          {
            desc: "玻璃",
            h: (h) => {
              return h - 50 - 128;
            },
            alias: {
              h: "高",
              w: "宽",
            },
            w: (w) => {
              return (w- 50 + 75) / 2 + 6 - 128;
            },
          },
        ],
      },
      {
        desc: "85型材",
        subParams: [
          {
            desc: "立柱",
            h: (h) => {
              return h;
            },
            alias: {
              h: "立柱",
              w: "横头",
            },
            w: (w) => {
              return w;
            },
          },
          {
            desc: "内框",
            h: (h) => {
              return h-50;
            },
            alias: {
              h: "光企",
              w: "上下方",
            },
            w: (w) => {
              return (w - 50 + 85) / 2 + 2;
            },
          },
          {
            desc: "玻璃",
            h: (h) => {
              return h - 50 - 148;
            },
            alias: {
              h: "高",
              w: "宽",
            },
            w: (w) => {
              return (w- 50 + 85) / 2 + 2 - 148;
            },
          },
        ],
      },
      {
        desc: "45 * 33 型材",
        subParams: [
          {
            desc: "立柱",
            h: (h) => {
              return h;
            },
            alias: {
              h: "立柱",
              w: "横头",
            },
            w: (w) => {
              return w;
            },
          },
          {
            desc: "内框",
            h: (h) => {
              return h-50;
            },
            alias: {
              h: "光企",
              w: "上下方",
            },
            w: (w) => {
              return (w - 43 + 47) / 2 ;
            },
          },
          {
            desc: "玻璃",
            h: (h) => {
              return h - 50 - 79;
            },
            alias: {
              h: "高",
              w: "宽",
            },
            w: (w) => {
              return (w- 43 + 47) / 2  - 79;
            },
          },
        ],
      },
      {
        desc: "18 * 35 型材",
        subParams: [
          {
            desc: "立柱",
            h: (h) => {
              return h;
            },
            alias: {
              h: "立柱",
              w: "横头",
            },
            w: (w) => {
              return w;
            },
          },
          {
            desc: "内框",
            h: (h) => {
              return h - 78;
            },
            alias: {
              h: "光企",
              w: "上下方",
            },
            w: (w) => {
              return (w - 28 + 18) / 2;
            },
          },
          {
            desc: "玻璃",
            h: (h) => {
              return h - 78 - 22;
            },
            alias: {
              h: "高",
              w: "宽",
            },
            w: (w) => {
              return (w- 28 + 18) / 2  - 22;
            },
          },
        ],
      },
      {
        desc: "16 * 45 型材",
        subParams: [
          {
            desc: "立柱",
            h: (h) => {
              return h;
            },
            alias: {
              h: "立柱",
              w: "横头",
            },
            w: (w) => {
              return w;
            },
          },
          {
            desc: "内框",
            h: (h) => {
              return h - 78;
            },
            alias: {
              h: "光企",
              w: "上下方",
            },
            w: (w) => {
              return (w - 28 + 16) / 2;
            },
          },
          {
            desc: "玻璃",
            h: (h) => {
              return h - 78 - 21;
            },
            alias: {
              h: "高",
              w: "宽",
            },
            w: (w) => {
              return (w- 28 + 16) / 2  -21;
            },
          },
        ],
      }
    ]
  }
];
