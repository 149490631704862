import { InputNumber, Select } from "antd";
import dayjs from "dayjs";
import { useState, Fragment } from "react";
import { useOutletContext } from "react-router-dom";

export const Result = () => {
  const [data] = useOutletContext();
  const [selectPara, setPara] = useState();

  const onSelect = (val) => {
    if (data) {
      const findOne = data.currentPro.params.find(
        (item, index) => index === val
      );
      setPara(findOne);
    }
  };

  if (!data) {
    return <div>请选择产品再打印</div>;
  }

  console.log(data);

  return (
    <div className="print">
      <div className="title">下料单</div>
      <div className="content">
        <div className="order-num">单号：{data.prodNum}</div>
        <div className="base-info">
          <div className="info-item">
            <span>客户名称：</span>
            {data.useName}
          </div>
          <div className="info-item">
            <span>打印日期：</span>
            {dayjs(Date.now()).format("YYYY-MM-DD HH:mm")}
          </div>
          <div className="info-item">
            <span>产品：</span>
            <div className="selector">
              <Select onSelect={onSelect}>
                {data.currentPro.params.map((pro, index) => (
                  <Select.Option key={pro.desc} value={index}>
                    {pro.desc}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
          <div className="info-item">
            <span>交货日期：</span>
            {data.time.format("YYYY-MM-DD HH:mm")}
          </div>
          <div className="info-item">
            <span>备注：</span>
            {data.remark}
          </div>
        </div>
        {selectPara && (
          <div className="product-info">
            <table>
              <thead>
                <tr>
                  <th>名称</th>
                  <th>参数</th>
                  <th>尺寸</th>
                  <th>数量</th>
                </tr>
              </thead>
              <tbody>
                {selectPara.subParams.map((subp) => (
                  <Fragment key={subp.desc}>
                    <tr>
                      <td rowSpan="2">{subp.desc}</td>
                      <td>{subp.alias.h}</td>
                      <td>{subp.h(data.height).toFixed(2)}mm</td>
                      <td>
                        <InputNumber type="text" />
                      </td>
                    </tr>
                    <tr>
                      <td>{subp.alias.w}</td>
                      <td>{subp.w(data.width).toFixed(2)}mm</td>
                      <td>
                        <InputNumber type="text" />
                      </td>
                    </tr>
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className="barcode-wrapper">
          <canvas id="barcode"></canvas>
        </div>
      </div>
    </div>
  );
};
